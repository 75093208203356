<!--
File: RoleEditForm.vue
Description: form for adding/editing a single user role.
-->
<template>
  <form>
    <md-card>
      <md-card-content>

        <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field :class="[{ 'md-valid': !errors.has('role_key') }, { 'md-error': errors.has('role_key') }]">
              <label for="role_key">{{ $t('stdCols.name') }}</label>
              <md-input id="role_key" v-model="role_key" type="role_key" data-vv-name="role_key" required
                v-validate="modelValidations.role_key"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-40">
            <md-field
              :class="[{ 'md-valid': !errors.has('description_en') }, { 'md-error': errors.has('description_en') }]">
              <label for="description_en">{{ $t('stdCols.description') }} ({{ ($t('translate.en')) }})</label>
              <md-input id="description_en" v-model="description_en" type="description_en" data-vv-name="description_en"
                required v-validate="modelValidations.description_en"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-40">
            <md-field
              :class="[{ 'md-valid': !errors.has('description_ru') }, { 'md-error': errors.has('description_ru') }]">
              <label for="description_ru">{{ $t('stdCols.description') }} ({{ ($t('translate.ru')) }})</label>
              <md-input id="description_ru" v-model="description_ru" type="description_ru" data-vv-name="description_ru"
                required v-validate="modelValidations.description_ru"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-40">
            <md-field
              :class="[{ 'md-valid': !errors.has('description_kg') }, { 'md-error': errors.has('description_kg') }]">
              <label for="description_kg">{{ $t('stdCols.description') }} ({{ ($t('translate.kg')) }})</label>
              <md-input id="description_kg" v-model="description_kg" type="description_kg" data-vv-name="description_kg"
                required v-validate="modelValidations.description_kg"></md-input>
            </md-field>
          </div>
          <md-field style="width: 50px; left: 15px">
            <md-switch v-model="active" id="active">{{ $t('road_network.active') }}</md-switch>
          </md-field>

        <div class="md-layout-item md-size-100 text-right">
          <md-button class="md-success" native-type="submit" @click.native.prevent="validate" :disabled="saveBtnDisabled">
            {{ $t('buttons.save') }}
          </md-button>
          <md-button class="md-accent" @click.stop.prevent="onClose">
            {{ $t('buttons.close') }}
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import messagesMixin from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'role-edit-form',
    mixins: [messagesMixin],

    data() {
      return {
        roleId: null,
        role_key: null,
        description_en: null,
        description_ru: null,
        description_kg: null,
        active: null,

        modelValidations: {
          role_key: { required: true, min: 1 },
          description_en: { required: true, min: 1 },
          description_ru: { required: true, min: 1 },
          description_kg: { required: true, min: 1 }
        }
      }
    },

    props: {
      oper: String
    },

    components: {
    },

    mounted() {
      //const { roleId = null } = this.$route.params
      this.roleId = this.$route.params?.role_id
      if (this.oper === 'upd' && this.roleId) {
        this.$store.dispatch('GET_ROLE_BY_ID', this.roleId).then((res) => {
          const theRole = Array.isArray(res) ? res[0] : res
          this.role_key = theRole.role_key
          this.description_en = theRole.role_description_en
          this.description_ru = theRole.role_description_ru
          this.description_kg = theRole.role_description_kg
          this.active = Boolean(theRole.active)
        })
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theRole = {
          role_key: this.role_key,
          role_description_en: this.description_en,
          role_description_ru: this.description_ru,
          role_description_kg: this.description_kg,
          active: Number(this.active)
        }

        const payload = this.oper === 'add' ? theRole : { id: this.roleId, role: theRole }
        let errDesc = ''
        try {
          await this.$store.dispatch(`${this.oper.toUpperCase()}_ROLE`, payload)
        } catch (err) {
          errDesc = err.message
        }
        this.savedMessage(errDesc, this.role_key, this.description_ru, this.description_en)
        this.onClose()
      }
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
      }
    },

    watch: {
    }
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>
  